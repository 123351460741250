<template>
  <el-dialog
    :title="'【详情】用户信息'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" label-width="100px" size="small">
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="用户名" prop="account">
            <el-input v-model="dataForm.account" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="真实姓名" prop="realName">
            <el-input v-model="dataForm.realName" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="身份证号码" prop="cardNo">
            <el-input v-model="dataForm.cardNo" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号" prop="mobilePhone">
            <el-input v-model="dataForm.mobilePhone" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="所属公司" prop="companyName">
            <el-input v-model="dataForm.companyName" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属车队" prop="motorcadeName">
            <el-input v-model="dataForm.motorcadeName" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="角色" size="mini" prop="roleIdList">
            <el-checkbox-group v-model="dataForm.roleIdList">
              <el-checkbox disabled="true" v-for="role in roleList" :key="role.id" :label="role.id">{{ role.roleName }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="状态" prop="status">
            <el-input v-model="dataForm.status" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      roleList: [],
      companyList: [],
      motorcadeList: [],
      dataForm: {
        id: null,
        account: null,
        realName: null,
        cardNo: null,
        mobilePhone: null,
        companyId: null,
        motorcadeId: null,
        roleIdList: [],
        status: 1
      }
    }
  },
  methods: {
    getMotorcadeList (companyId) {
      this.motorcadeList = []
      if (!companyId || companyId == null) {
        return
      }
      this.$http({
        url: this.$http.adornUrl('/motorcade/findListBySelect'),
        method: 'get',
        params: this.$http.adornParams({
          companyId: companyId
        })
      }).then(({data}) => {
        this.motorcadeList = data
      })
    },
    init (id) {
      this.dataForm.id = id || 0
      this.$http({
        url: this.$http.adornUrl('/system/user/userPageInit'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        this.roleList = data.roleList
        this.companyList = data.companyList
      }).then(() => {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
        })
      }).then(() => {
        if (this.dataForm.id) {
          this.$http({
            url: this.$http.adornUrl(`/system/user/${this.dataForm.id}`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({data}) => {
            this.dataForm.account = data.account
            this.dataForm.realName = data.realName
            this.dataForm.cardNo = data.cardNo
            this.dataForm.mobilePhone = data.mobilePhone
            this.dataForm.companyId = data.companyId
            this.dataForm.motorcadeId = data.motorcadeId
            this.dataForm.roleIdList = data.roleIdList
            this.dataForm.status = data.status
            this.getMotorcadeList(data.companyId)
          })
        }
      })
    }
  }
}
</script>
